export const formLink = "https://docs.google.com/forms/d/e/1FAIpQLSexeiSGLUCZuzyz9BDIzEcNmf0OLfJQPxQvP5JHsS3pV3okzw/viewform"
export const items = [
  // = = = = = = = = = = = = = = = = = = = = =
  // {
  //   assertName: "_",
  //   storeName: "掲載名",
  //   area: ["エリア"],
  //   type: ["授業形式"],
  //   target: ["対象年齢"],
  //   price: ["価格"],
  //   pointText: [
  //     "",
  //   ],
  //   // link: "独自リンク",
  //   // point: ["表組みポイント"],
  //   // price1: "表組み価格1",
  //   // staff: ["staff1","staff2","staff2"],
  // },
  // = = = = = = = = = = = = = = = = = = = = =
  {
    assertName: "choklo",
    storeName: "LPK Choklo",
    type: ["Tatap Muka"],
    target: ["18-35 Tahun"],
    price: [],
    pointText: [
      "Karir Langsung di Jepang!||Setelah mendapatkan pengalaman kerja di perusahaan grup kami di Indonesia, kamu bisa langsung melanjutkan karirmu di Jepang dan menunjukkan kemampuanmu.",
      "Karir Cemerlang Setelah Kembali!||Setelah kembali ke Indonesia, kamu bisa memanfaatkan keterampilan dan pengalaman dari Jepang untuk mengembangkan karirmu di perusahaan grup kami.",
      "Peluang Kerja Melimpah!||Dengan kerjasama kami bersama perusahaan-perusahaan besar di Jepang, banyak kesempatan kerja tersedia, jadi kamu bisa menemukan pekerjaan yang paling sesuai."
    ],
  },
  {
    assertName: "atc",
    storeName: "LPK ATC",
    type: ["Tatap Muka"],
    target: ["18-30 Tahun"],
    price: [],
    pointText: [
      "Spesialisasi di Industri Manufaktur!||Sekitar 70% peluang kerja berasal dari industri manufaktur, sangat cocok buat kamu yang tertarik di bidang pengolahan. Pilihlah dari berbagai kesempatan yang tersedia.",
      "Rekam Jejak Terpercaya!||Dengan pengalaman mengirim lebih dari 600 peserta magang ke Jepang, kami memiliki sistem pendukung yang kuat dan terpercaya.",
      "Pengakuan dari Pemerintah!||Kami bangga meraih peringkat kedua dalam Pelatihan Vocasi Award 2019, sebuah penghargaan yang menunjukkan tingkat kepercayaan tinggi dari pemerintah.",
    ],
  },
  {
    assertName: "nakayoshi",
    storeName: "LPK Nakayoshi Gakuin Center",
    type: ["Tatap Muka"],
    target: ["18-25 Tahun"],
    price: [],
    pointText: [
      "Pengajaran Otentik oleh Guru Jepang!||Dalam empat bulan, kamu akan diajari langsung oleh lima guru Jepang yang berpengalaman, siap membantu kamu mencapai level N4.",
      "Sistem Dukungan Handal!||Kami bangga dengan tingkat kehadiran sempurna, dan setelah tiba di Jepang, kami akan terus mendukungmu untuk menyelesaikan berbagai masalah yang mungkin kamu hadapi di tempat kerja.",
      "Peluang Kerja Manufaktur Terbuka Lebar!||70% peluang kerja kami ada di industri manufaktur, sehingga kamu bisa menemukan tempat kerja yang sesuai dengan keahlianmu.",
    ],
  },
  {
    assertName: "anugerah",
    storeName: "LPK PT INDONESIA NIPPON ANUGERAH",
    type: ["Tatap muka"],
    target: ["19-29 tahun"],
    price: ["Pilih LPK yang akan diperiksa"],
    pointText: [
      "Pelatihan selama satu bulan di perusahaan Indonesia sebelum masuk ke Jepang. ||Siswa akan dipersiapkan untuk segera bekerja di pasar lokal.",
      "Biaya pendidikan di Indonesia tanpa biaya, bekerja sama dengan bank. ||Pembayaran fleksibel setelah tiba di Jepang.",
      "Sebanyak lebih dari 1.300 orang telah dilayani sejauh ini.||Sebanyak 1.358 siswa telah berhasil mendapatkan pekerjaan di Jepang, dan kami memiliki rekam jejak yang baik dengan berbagai peluang kerja.",
    ],
    // link: "https://www.google.com/2"
  },
  {
    assertName: "homare",
    storeName: "LPK Homare",
    type: ["Tatap Muka"],
    target: ["18-28 tahun"],
    price: [],
    pointText: [
      "Pengajaran langsung dari ahli Jepang!||Empat guru Jepang berpengalaman akan mengajarkanmu langsung di Indonesia. Selain belajar bahasa, kamu juga akan mempelajari tata krama dan etos kerja khas Jepang yang sangat dihargai di dunia kerja.",
      "Dukungan penuh sepanjang waktu!||Staf Jepang yang tinggal di lokasi siap membantumu mengatasi berbagai masalah, baik saat belajar maupun dalam kehidupan sehari-hari. Kamu bisa selalu mengandalkan mereka ketika butuh bantuan.",
      "Kecepatan menuju Jepang!||Dengan banyaknya staf Jepang, kami selalu memiliki informasi terbaru tentang lowongan pekerjaan di Jepang. Segera setelah kamu siap, kami akan membantu mewujudkan impianmu untuk bekerja di Jepang secepat mungkin.",
    ],
  },
  {
    assertName: "hadetama",
    storeName: "HADETAMA",
    type: ["Tatap Muka"],
    target: ["18-28 Tahun"],
    price: [],
    pointText: [
      "Program Belajar Cepat!||Dalam waktu hanya 3-6 bulan pelatihan intensif, kamu bisa meningkatkan kemampuan bahasa Jepang dengan cepat dan efisien.",
      "Kebebasan Pilih Pekerjaan!||Kamu bebas memilih tempat kerja sesuai minatmu, sehingga kamu lebih termotivasi untuk bekerja dengan baik dan bertahan lebih lama.",
      "Capai N4 dalam waktu singkat!||Dengan pengajaran berkualitas tinggi, kami akan mempersiapkanmu untuk meraih sertifikasi N4 dalam waktu singkat, membawa kamu lebih dekat ke pekerjaan impian di Jepang.",
    ],
  },
  {
    assertName: "jiritsu",
    storeName: "LPK Jiritsu",
    type: ["Tatap Muka"],
    target: ["18-25 Tahun"],
    price: [],
    pointText: [
      "Kurikulum untuk Pertumbuhan Menyeluruh!||Selain mempelajari bahasa dan budaya Jepang, kamu juga akan memperkuat soft skills serta mengembangkan kepribadian melalui metode SQ (Spiritual Quotient). Program intensif selama 3 bulan ini memastikan pertumbuhan yang seimbang.",
      "Dukungan Berkelanjutan!||Setelah masa magang selesai, kami akan terus mendukungmu agar kamu bisa mandiri dan sukses dalam karir jangka panjang, baik di Jepang maupun setelah kembali ke Indonesia.",
    ],
  },
]